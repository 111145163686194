<style lang="scss" scoped>
/* PC端样式 */
@media screen and (min-width: 769px) {
  .content6 {
    min-height: 300px;
    height: 100%;
  }

  .pageReg_text {
    width: 160%;
    color: #4f9576;
    font-family: Roboto, Arial, Poppins;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
  }

  .filtergly {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray !important;
  }

  .typle-list {
    h5 {
      color: #02758a;
    }

    .change-list {
      margin: 3px;

      .change-list {
        margin: 0 10px;
      }
    }

    .el-check-tag {
      margin: 10px 10px;
    }

    .big-list {
      display: flex;
      margin: 10px 0;
      flex-wrap: wrap;

      .change-active {
        list-style: none;
        margin: 10px;
        padding: 5px;
        background-color: #dfe2e2;
        border-radius: 15px;
        color: #146e65;
        font-size: 12px;
        cursor: pointer;
      }

      .tags-select-active {
        background-color: #dfe2e2;
        color: #02758a;
      }
    }

    .el-checkbox-button {
      margin: 5px;
      border: 1px;
    }

    :deep(.el-checkbox-button__inner) {
      border: 1px solid #d8d4d4;
      border-radius: 20px;
      font-size: 12px;
    }
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 70px;
    line-height: 45px;
    background: #ffffff;
    color: #ffff;
    flex: 0 0 auto;
    height: 64px;

    h3 {
      width: 300px;
      color: #000000;
    }

    .head-left {
      cursor: pointer;
      // margin-top: 15px;
      width: 300px;
      height: 70px;

      img {
        width: 246.3px;
        height: 70px;
      }
    }

    .head-right1 {
      width: 200px;

      .head-right {
        background-color: #e49c17;
        width: 100px;
        line-height: 35px;
        font-size: 12px;
        color: black;
        text-align: center;
        cursor: pointer;
        border-radius: 35px;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: column;

    /* 设置容器高度为视口高度，保证布局占满整个屏幕 */
  }

  .middle {
    width: 100%;
    background: #deeff3;
    // height: 860px;
    overflow-y: scroll;
    overflow: hidden;
    flex: 1 1 auto;
    padding-bottom: 100px;

    .content {
      width: 900px;
      background-color: #fff;
      margin: auto;
      padding: 15px;
      margin-top: 100px;
      // margin-bottom: 149px;

      .mid-head {
        width: 300px;
        height: 200px;
      }

      .mid-content {
        margin-top: 50px;
        text-align: center;
        padding-bottom: 50px;
        border-bottom: 1px solid #02758a;

        .mid-reg {
          width: 600px;
          margin: auto;
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .login-input {
            width: 350px;
          }
        }

        .reg {
          margin: auto;
          margin-top: 20px;
          width: 160px;
          height: 50px;
          line-height: 50px;
          background-color: #f7e816;
          color: #000;
        }

        :deep(.el-button) {
          border: none;
        }

        .reg-foot {
          margin: auto;
          margin-top: 30px;
        }
      }

      .foot {
        margin-top: 30px;
        text-align: center;
      }

      .content2 {
        min-height: 500px;
        padding: 50px 219px;
        color: #71adb8;
        // border-bottom: 1px solid #02758a;

        h3 {
          margin: 20px;
          margin-left: 160px;
          color: #02758a;
        }

        p {
          line-height: 30px;
        }

        .adress {
          margin: 30px 0;
        }
      }

      .content3 {
        min-height: 300px;
        height: 100%;
        text-align: center;

        .cont-phone {
          margin-top: 30px;
        }

        .cont-icon {
          margin-top: 30px;
        }

        .cont-adress {
          margin: 30px 0;
          color: #71adb8;
        }

        .cont-on {
          line-height: 30px;
          background-color: #d69f06;
          padding: 8px 25px;
          cursor: pointer;
          border-radius: 30px;
        }
      }

      .content4 {
        width: 500px;
        margin: auto;

        .information {
          padding: 30px 20px;
          text-align: center;
          border-bottom: 1px solid #02758a;

          .infor-input {
            // margin-bottom: 45px;
            .infor-name {
              width: 150px;
            }
          }

          .infor-word {
            font-size: 12px;
            text-align: left;
            color: #71adb8;
            line-height: 16px;
          }

          .next {
            border-radius: 5px;
            padding: 10px 30px;
            background-color: #d69f06;
            cursor: pointer;
          }
        }

        .foot4 {
          text-align: center;
          margin-top: 6px;
        }
      }

      .content5 {
        .inforUplaod {
          width: 680px;
          margin: auto;

          h3 {
            margin: 30px 0 20px 0;
            text-align: center;
          }

          p {
            text-align: center;
            margin-bottom: 20px;
          }
        }

        .infor-fail {
          display: flex;
          justify-content: space-between;
          margin-right: 5px;
        }

        .infor-remind {
          margin: 30px 0;

          li {
            line-height: 25px;
            color: #71adb8;
          }
        }

        .infor-message {
          border-top: 1px solid #02758a;
          line-height: 50px;
          border-bottom: 1px solid #02758a;
          text-align: center;
        }

        .infor-finish {
          margin-top: 40px;
          line-height: 50px;
          text-align: center;
          margin-bottom: 20px;

          span {
            padding: 10px 40px;
            background-color: #ecd714;
            cursor: pointer;
            border-radius: 50px;
          }
        }
      }
    }
  }

  .footer {
    flex: 0 0 auto;
    height: 150px;
  }
}

/* 移动端样式 */
@media screen and (max-width: 768px) {
  .filtergly {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
    filter: gray !important;
  }

  .typle-list {
    h5 {
      color: #02758a;
    }

    .change-list {
      margin: 3px;

      .change-list {
        margin: 0 10px;
      }
    }

    .el-check-tag {
      margin: 10px 10px;
    }

    .big-list {
      display: flex;
      margin: 10px 0;
      flex-wrap: wrap;

      .change-active {
        list-style: none;
        margin: 10px;
        padding: 5px;
        background-color: #dfe2e2;
        border-radius: 15px;
        color: #146e65;
        font-size: 12px;
        cursor: pointer;
      }

      .tags-select-active {
        background-color: #dfe2e2;
        color: #02758a;
      }
    }

    .el-checkbox-button {
      margin: 5px;
      border: 1px;
    }

    :deep(.el-checkbox-button__inner) {
      border: 1px solid #d8d4d4;
      border-radius: 20px;
      font-size: 12px;
    }
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 45px;
    background: #ffffff;
    color: #ffff;
    flex: 0 0 auto;
    height: 60px;
    padding: 1%;

    .head-left {
      cursor: pointer;

      img {
        margin-top: 10px;
        width: 90px;
        height: 35px;
      }
    }

    .head-right {
      background-color: #e49c17;
      width: 80px;
      line-height: 35px;
      font-size: 12px;
      color: black;
      text-align: center;
      cursor: pointer;
      border-radius: 15px;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* 设置容器高度为视口高度，保证布局占满整个屏幕 */
  }

  .middle {
    width: 100%;
    height: 65vh;
    overflow: hidden;
    flex: 1 1 auto;
    overflow-y: scroll;
    // padding-bottom: 100px;

    .content {
      width: 100%;
      background: linear-gradient(180deg, lightblue, #fff, #fff) !important;
      margin: auto;
      padding-top: 45px;
      margin-bottom: 149px;

      .mid-head {
        width: 300px;
        height: 200px;
      }

      .mid-content {
        margin-top: 50px;
        text-align: center;
        // padding-bottom: 50px;

        .mid-reg {
          width: 100%;
          margin: auto;
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          .login-input {
            width: 350px;
          }
        }

        .reg {
          margin: auto;
          margin-top: 20px;
          width: 160px;
          height: 50px;
          line-height: 50px;
          background-color: #f7e816;
          color: #000;
        }

        :deep(.el-button) {
          border: none;
        }

        .reg-foot {
          margin: auto;
          margin-top: 30px;
        }
      }

      .foot {
        margin-top: 30px;
        text-align: center;
      }

      .content2 {
        width: 100%;
        color: #71adb8;
        min-height: 300px;
        height: 100%;

        h3 {
          text-align: center;
          height: 100px;
          padding: 1%;

          line-height: 100px;
          color: #02758a;
        }

        p {
          line-height: 30px;
          text-align: center;
        }

        .adress {
          margin: 30px 0;
        }
      }

      .content3 {
        text-align: center;

        .cont-phone {
          margin-top: 30px;
        }

        .cont-icon {
          margin-top: 30px;
        }

        .cont-adress {
          margin: 30px 0;
          color: #71adb8;
        }

        .cont-on {
          line-height: 30px;
          background-color: #d69f06;
          padding: 8px 25px;
          cursor: pointer;
          border-radius: 30px;
        }
      }

      .content4 {
        width: 100%;
        margin: auto;
        min-height: 300px;
        height: 100%;

        .information {
          padding: 30px 20px;
          text-align: center;
          border-bottom: 1px solid #02758a;

          .infor-input {
            // margin-bottom: 45px;
            .infor-name {
              width: 150px;
            }
          }

          .infor-word {
            font-size: 12px;
            text-align: left;
            color: #71adb8;
            line-height: 16px;
          }

          .next {
            border-radius: 5px;
            padding: 10px 30px;
            background-color: #d69f06;
            cursor: pointer;
          }
        }

        .foot4 {
          text-align: center;
          margin-top: 6px;
        }
      }

      .content5 {
        width: 100%;

        .inforUplaod {
          width: 98%;
          margin: auto;

          h3 {
            margin: 30px 0 20px 0;
            text-align: center;
          }

          p {
            text-align: center;
            margin-bottom: 20px;
          }
        }

        .infor-fail {
          text-align: center;
          justify-content: space-between;
          margin-right: 5px;
        }

        .infor-remind {
          margin: 30px 0;

          li {
            line-height: 25px;
            color: #71adb8;
          }
        }

        .infor-message {
          border-top: 1px solid #02758a;
          line-height: 50px;
          border-bottom: 1px solid #02758a;
          text-align: center;
        }

        .infor-finish {
          margin-top: 40px;
          line-height: 50px;
          text-align: center;
          margin-bottom: 20px;

          span {
            padding: 10px 40px;
            background-color: #ecd714;
            cursor: pointer;
            border-radius: 50px;
          }
        }
      }
    }
  }

  .footer {
    flex: 0 0 auto;
    height: 180px;
  }

  .pageReg_text {
    width: 100%;
    color: #4f9576;
    font-family: Roboto, Arial, Poppins;
    font-size: 14px;
    font-weight: 300;
    text-align: center;
  }
}
</style>

<template>
  <div>
    <div class="container">
      <div class="head">
        <div class="head-left" @click="goOnlineExpert">
          <img src="../../assets/mainLogo.png" alt="" />
        </div>
        <h3 style="color: #000000">Expert Registration</h3>
        <div class="head-right1">
          <p v-if="data.active == 1" class="head-right" @click="goLogin">Log in</p>
          <p v-else class="head-right" @click="goToLogin">Log out</p>
        </div>
      </div>

      <div class="middle">
        <div class="content">
          <el-steps :active="data.active" align-center>
            <el-step />
            <el-step />
            <el-step />
            <el-step />
            <el-step />
            <el-step />
          </el-steps>
          <div v-if="data.active == '1'">
            <div class="mid-content">
              <h3>Expert Sign Up</h3>
              <div class="mid-reg">
                <el-form
                  ref="registerFromRef"
                  :model="ruleForm"
                  status-icon
                  :rules="rules"
                  label-width="100px"
                  class="demo-ruleForm"
                >
                  <el-form-item label="Email:" prop="email" class="login-input">
                    <el-input v-model="ruleForm.email" type="email" autocomplete="off" />
                  </el-form-item>
                  <el-form-item label="Password:" prop="password" class="login-input">
                    <el-input
                      v-model="ruleForm.password"
                      type="password"
                      autocomplete="off"
                    />
                  </el-form-item>
                  <el-form-item> </el-form-item>
                </el-form>
                <div class="pageReg_text">
                  By signing up, you agree to our
                  <span
                    style="color: #046739; cursor: pointer; font-weight: 700"
                    @click="router.push('/pageTerm')"
                  >
                    Terms & Conditions and
                  </span>
                  <span
                    style="color: #046739; cursor: pointer; font-weight: 700"
                    @click="router.push('/privacyPolicy')"
                  >
                    Privacy Policy
                  </span>
                </div>
                <el-button
                  class="reg"
                  type="primary"
                  :loading-icon="Eleme"
                  :loading="createLoading"
                  @click="regEmail"
                  >Create An Account</el-button
                >
              </div>
            </div>
          </div>
          <div class="content2" v-if="data.active == '2'">
            <h3>Confirm your email</h3>
            <p>
              To continue your registration, please check your inbox and click on the
              confirmation link in the email we just sent to:
            </p>
            <div style="margin-top: 10px">
              If you do not receive the email, click the
              <span
                style="color: #02758a; font-weight: 600; cursor: pointer"
                @click="debReSend"
                >Resend</span
              >
              to send it again
            </div>
            <p
              style="color: cornflowerblue; cursor: pointer"
              v-if="data.emailValue || userInfo"
            >
              {{ data.emailValue || userInfo.email }}
            </p>
            <div class="adress"></div>
            <div></div>
          </div>
          <div class="content3" v-if="data.active == '3'">
            <h3 class="cont-phone">Email confirmation</h3>
            <div class="cont-icon"></div>
            <p class="cont-adress">You have confirmed your email address</p>
            <span class="cont-on" @click="goOn">Continue</span>
          </div>
          <div class="content4" v-if="data.active == '4'">
            <div class="information">
              <h3>provide personal information</h3>
              <el-form
                label-position="top"
                label-width="100px"
                :model="formLabelAlign"
                :rules="rulesmage"
                ref="formLabelAlignRef"
                style="max-width: 460px; margin-bottom: 30px"
              >
                <!--username  nickname -->
                <el-form-item prop="username" label="Full Name:" class="infor-input">
                  <el-input v-model="formLabelAlign.username" placeholder="Full Name" />
                </el-form-item>
                <el-form-item label="Display name:" prop="nickname" :rules="rulesmage">
                  <el-input
                    v-model="formLabelAlign.nickname"
                    placeholder="Display name"
                  />
                </el-form-item>
                <div class="infor-word">
                  <p>A-Z, 3-16 characters</p>
                  <ul>
                    <li>
                      Make up a real-sounding name. Note that first and last names are
                      capitalized (Example: SusanSmith)
                    </li>
                    <li>
                      Your display name will appear on your profile and be visible in
                      search results
                    </li>
                  </ul>
                </div>
                <el-form-item label="Date of birth:" prop="year">
                  <el-date-picker
                    v-model="formLabelAlign.value4"
                    type="date"
                    placeholder="Please enter your date"
                  />
                </el-form-item>
                <el-form-item label="Gender" prop="gender">
                  <el-select v-model="formLabelAlign.gender" placeholder="Gender">
                    <el-option label="Male" value="0" />
                    <el-option label="Female" value="1" />
                  </el-select>
                </el-form-item>
                <el-form-item label="Recommended from:" prop="type">
                  <el-input
                    v-model="formLabelAlign.type"
                    placeholder="Recommended from"
                  />
                </el-form-item>
                <div
                  style="
                    text-align: left;
                    color: #606266;
                    font-size: 14px;
                    line-height: 20px;
                  "
                >
                  Speciality
                </div>
                <p
                  style="
                    text-align: left;
                    color: #71adb8;
                    font-size: 12px;
                    line-height: 15px;
                  "
                >
                  Here you can choose your expertise. In this way, members can search for
                  you through the services you provide and know what to expect. Please
                  note that you can become an expert in up to 3 sections
                </p>
                <div class="typle-list">
                  <template v-for="item in state.typeList" :key="item.id">
                    <h5>
                      {{ state[`typeList${item.id}`].name
                      }}<span style="margin-left: 4px"
                        >{{ state[`changeList${item.id}`].length }}/3</span
                      >
                    </h5>
                    <div class="change-list">
                      <el-checkbox-group
                        v-model="state[`changeList${item.id}`]"
                        size="large"
                        :min="0"
                        :max="3"
                      >
                        <el-checkbox-button
                          v-for="city in state[`typeList${item.id}`].categoryList"
                          :key="city.id"
                          :label="city.id"
                          :value="city.id"
                          @change="onChange(city)"
                        >
                          {{ city.name }}
                        </el-checkbox-button>
                      </el-checkbox-group>
                    </div>
                  </template>
                </div>
              </el-form>
              <span class="next" @click="saveMessage">Next</span>
            </div>
            <div class="foot4">
              need help? to get &nbsp;
              <span style="color: #f7e816; cursor: pointer">live support</span>
            </div>
          </div>
          <div class="content5" v-if="data.active == '5'">
            <div class="inforUplaod">
              <h3>Upload files</h3>
              <p>
                Upload photos of both the front and back of your ID card, and then take
                another photo with your ID card in hand.
              </p>
              <div class="infor-fail">
                <div>
                  <div style="text-align: center; margin-bottom: 5px; width: 150px">
                    Reverse
                  </div>
                  <div>
                    <ImageUpload
                      v-model:imgUrl="imageform.reverseImg"
                      :token="data.conToken"
                      :limit="1"
                    ></ImageUpload>
                  </div>
                </div>
                <div>
                  <div style="text-align: center; margin-bottom: 5px; width: 150px">
                    Front
                  </div>
                  <div>
                    <ImageUpload
                      v-model:imgUrl="imageform.frontImg"
                      :limit="1"
                    ></ImageUpload>
                  </div>
                </div>
                <div>
                  <div style="text-align: center; margin-bottom: 5px; width: 150px">
                    Selfie
                  </div>
                  <div>
                    <ImageUpload
                      v-model:imgUrl="imageform.selfImg"
                      :limit="1"
                    ></ImageUpload>
                  </div>
                </div>
              </div>
              <ul class="infor-remind">
                <li>
                  Make sure that the photos you upload are as clear and formatted as our
                  legend.。
                </li>
                <li>
                  Please note that you can only upload high-quality, high-definition
                  photos
                </li>
                <li>
                  Blurred photos will be rejected and you will need to upload them again.
                </li>
                <li>
                  Your face should be clearly visible, please do not wear hats, glasses,
                  masks, and other items.
                </li>
              </ul>
              <div class="infor-message">
                Your document must be reviewed before approval. We will contact you within
                24 hours
              </div>
              <div class="infor-finish">
                <span @click="goConCenter">Finish</span>
              </div>
            </div>
          </div>
          <div class="content6" v-if="data.active == '6'">
            <div style="text-align: center; margin-top: 60px">
              <h2>Waiting for approval</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script setup name="pageReg">
import Footer from "../.././layout/components/Footer.vue";
import { reactive, ref, onMounted, computed, watch } from "vue";
import router from "../../router";
import {
  conRegister,
  postInformation,
  querEmail,
  sendPhone,
  verifyCode,
  getThree,
} from "@/api/sys.js";
import { getTypeList, addTypeList, logOut } from "../../api/center.js";
import { ElMessage, ElMessageBox, ElLoading } from "element-plus";
import ImageUpload from "@/components/ImageUpload";
import {
  setToken,
  getToken,
  removeToken,
  getType,
  setType,
  removeType,
  removeConlant,
} from "@/utils/auth";
import request from "@/utils/request.js";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { before } from "lodash";
import { debounce } from "lodash";
const routers = useRoute();
const router1 = useRouter();

const url = window.location.origin;
const store = useStore();
const data = reactive({
  active: store.state.registerActive || 1, //控制步骤
  //active: 6, //控制步骤
  emailValue: "",
  timer: "",
  conToken: "", //邮箱注册返回的token
  consulutobj: {},
});
const props = defineProps({
  consultantId: {
    type: Number || "",
    default: "",
  },
});
const userInfo = computed(() => JSON.parse(store.state.userInfo));
const createLoading = ref(false);
//邮箱验证
const checkEmail = (rule, value, callback) => {
  // const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
  const mailReg = /^[a-zA-Z0-9_.-]+(_?@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)$/;
  if (!value) {
    return callback(new Error("Email cannot be empty"));
  }
  setTimeout(() => {
    if (mailReg.test(value)) {
      callback();
    } else {
      callback(new Error("Please enter the correct email format"));
    }
  }, 100);
};
const activeVal = computed(() => {
  return store.state.registerActive;
});
watch(activeVal, (n, o) => {
  console.log("n, o", n, o);
});
//密码验证
const validatePass = (rule, value, callback) => {
  const pwdReg = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
  if (!value) {
    return callback(new Error("Please enter the password"));
  } else if (pwdReg.test(value)) {
    callback();
  } else {
    callback(
      new Error(
        "Please enter a password that contains no less than 8 digits of numbers and letters"
      )
    );
  }
};

//身份证三张照片
const imageform = ref({
  reverseImg: "",
  frontImg: "",
  selfImg: "",
  auditStatus: "5",
});
const rules = ref({
  email: [{ required: true, validator: checkEmail, trigger: "blur" }],
  password: [{ required: true, validator: validatePass, trigger: "blur" }],
});
const rulesmage = ref({
  username: [
    { required: true, message: "Please fill in the information", trigger: "blur" },
  ],
  // username2: [{ required: true, message: 'Please fill in the information', trigger: 'blur' }],
  // fristName: [{ required: true, message: 'Please fill in the information', trigger: 'blur' }],
  // lastName: [{ required: true, message: 'Please fill in the information', trigger: 'blur' }],
  nickname: [
    { required: true, message: "Please fill in the information", trigger: "blur" },
  ],
  gender: [
    { required: true, message: "Please fill in the information", trigger: "blur" },
  ],
  // mobile: [{ required: true, message: 'Please fill in the information', trigger: 'blur' }],
  // level: [{ required: true, message: 'Please fill in the information', trigger: 'blur' }],
  lastName: [
    { required: true, message: "Please fill in the information", trigger: "blur" },
  ],
});
const ruleForm = ref({
  email: "",
  password: "",
});
const formLabelAlign = ref({
  username: "",
  fristName: "",
  lastName: "",
  nickname: "",
  // year: '',
  // mouth: '',
  // day: '',
  value4: "",
  gender: "",
  // mobile: '',//手机号
  // code:'',//验证码
  type: "", ///推荐
  // level: '' ///等级
});

const state = reactive({
  typeList: [],
});

//第四步 数据
const getTypeListFn = () => {
  getTypeList().then((res) => {
    if (res.code == 200) {
      state.typeList = res.rows.slice(0, 2);
      console.log(state.typeList, "state.typeList总 ");
      res.rows.forEach((item) => {
        state[`typeList${item.id}`] = item;
        state[`changeList${item.id}`] = [];
      });
      getConsuLant();
    }
  });
};

const onChange = (item) => {
  const param = { consultantId: props.consultantId, id: item.id };
  addTypeList(param).then((res) => {
    if (res.code == 200) {
      // getConsuLant()
      // ElMessage({
      //     message: 'Add successfully',
      //     type: 'success'
      // })
    }
  });
};

//发送手机号验证
// const sendMobile = async () => {
//   console.log(111)
//   let res = await sendPhone({phoneNumber: formLabelAlign.value.mobile })
//   console.log(res)
// }

// const text = async()=> {
//   let res = await verifyCode({ phoneNumber: formLabelAlign.value.mobile, smsCode: formLabelAlign.value.code })
//   console.log
// }

const timeConversion = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear(); // 获取年份
  const month = date.getMonth() + 1; // 获取月份（需要加1，因为月份范围是0-11）
  const day = date.getDate(); // 获取日期

  const formattedDate = `${year}${month
    .toString()
    .padStart(2, "0")}${day.toString().padStart(2, "0")}`;
  console.log(formattedDate); // 输出格式化后的日期，例如：2023-08-01
  return formattedDate;
};

// watch(store.state.registerActive, (newVal,oldVal) => {
//   date.active = newVal
// })

onMounted(() => {
  const path = window.location.href;
  if (path.includes("?")) {
    const a = path.indexOf("?");
    const seach1 = path.slice(a + 1, a + 5);
    console.log(a, path);
    const aa = path.indexOf("=");
    const codeNum = path.slice(aa + 1, path.length);
    if (seach1 == "code") {
      store.commit("setRegisterActive", 3);
      data.active = 3;
      threeFn(codeNum);
    }
  }

  getTypeListFn();

  console.log(window.location.origin);

  window.scrollTo(0, 0);
  if (getType() == "1") {
    getLoginConsuInfo();
  }
});

const threeFn = async (codeNum) => {
  let res = await getThree({ code: codeNum });
  console.log(res);
};

const showActive = () => {};

const goNextLogin = () => {
  router.push("/login");
};
//定时器的函数
const timerFn = async () => {
  let timer = null;

  const fetchData = async () => {
    try {
      let res = await request.get("/system/faConsultant/getLoginConsuInfo");
      if (res.code === 200) {
        if (res.data.auditStatus === "1") {
          ElMessage.success("Email confirmation successful!");
          store.commit("setRegisterActive", 3);
          data.active = 3;
          data.consulutobj = res.data;
          clearInterval(timer);
        } else if (res.data.auditStatus === "2") {
          ElMessage.success("Pass the examination!");
          clearInterval(timer);
          router.push("/profileInfo");
        }
      }
    } catch (error) {
      console.log("请求错误:", error);
    }
  };

  const startTimer = async () => {
    await fetchData(); // 执行第一次请求

    timer = setInterval(async () => {
      await fetchData();
    }, 3000);
  };

  await startTimer(); // 启动定时器

  // 在上一次请求结束后停止3秒再进行下一次请求
  const stopTimerAndStartNext = async () => {
    clearInterval(timer);
    await new Promise((resolve) => setTimeout(resolve, 3000));
    startTimer();
  };

  // 监听请求成功并且 auditStatus 为 '1'，停止定时器并延迟3秒后再次开始请求
  watch(
    () => res.data.auditStatus,
    async (newValue, oldValue) => {
      if (newValue === "1" || "2") {
        await stopTimerAndStartNext();
      }
    }
  );
};

//邮箱注册
const registerFromRef = ref(null);
//第一步注册
const regEmail = () => {
  console.log(ruleForm.value, "oooo");
  registerFromRef.value.validate(async (valid) => {
    if (valid) {
      createLoading.value = true;
      const res = await conRegister(ruleForm.value);
      if (res.code == 200) {
        createLoading.value = false;
        console.log(res, "顾问注册");
        setToken(res.data.data.access_token);
        // window.localStorage.setItem('token', res.data.data.access_token)
        // data.conToken = res.data.access_token
        setType("1");
        data.emailValue = ruleForm.value.email;
        ElMessage({
          type: "success",
          grouping: true,
          message: res.msg,
        });

        store.commit("setRegisterActive", 2);
        data.active = 2;

        getLoginConsuInfo();
        timerFn();
      } else {
        createLoading.value = false;
        console.log(res.msg);
        ElMessage({
          type: "error",
          grouping: true,
          message: res.msg,
        });
      }
    } else {
    }
  });
};

//第二步注册
const comfirmEmail = () => {
  querEmail({ email: data.emailValue || JSON.parse(store.state.userInfo).username }).then(
    (res) => {
      if (res.code == 200) {
        console.log(res, "邮箱确认");
        if (res.data.data.auditStatus == "1") {
          ElMessage.success("Email confirmation successful!");
          store.commit("setRegisterActive", 3);
          data.active = 3;
          data.consulutobj = res.data.data;
        } else {
          ElMessage.error("Please confirm in your email that this is you!");
        }
      } else {
        ElMessage.error({
          grouping: true,
          message: res.msg,
        });
      }
    }
  );
};

//第二步再次发送消息
const reSend = async () => {
  const { code } = await request.post("/auth/faConsultant/sendEmail");
  if (code == 200) {
    ElMessage({
      message: "Successfully resend your email address",
      type: "success",
      duration: 1500,
    });
  } else {
    ElMessage.error({
      grouping: true,
      message: res.msg,
    });
  }
};
const debReSend = debounce(reSend, 700);

//第三步注册
const goOn = () => {
  getLoginConsuInfo11();
  if (data.consulutobj.auditStatus == "0") {
    ElMessage({
      type: "waiting",
      grouping: true,
      message: "Email not registered, please register",
    });
    comfirmEmail();
  } else if (data.consulutobj.auditStatus == "1") {
    store.commit("setRegisterActive", 4);
    data.active = 4;
  }
};

//保存用户信息  !!! 要传账号注册的token
//第四步注册
const formLabelAlignRef = ref(null);
const saveMessage = () => {
  formLabelAlignRef.value.validate((valid) => {
    if (valid) {
      const params = {
        id: data.consulutobj.id,
        username: formLabelAlign.value.username,
        // username: formLabelAlign.value.fristName + formLabelAlign.value.lastName,
        nickname: formLabelAlign.value.nickname,
        gender: formLabelAlign.value.gender,
        level: "0-5year",
        type: formLabelAlign.value.type,
        // mobile: formLabelAlign.value.mobile,
        // code: formLabelAlign.value.code,
        birthday: timeConversion(formLabelAlign.value.value4),
        auditStatus: "4",
      };
      console.log(params, "对dui对");
      postInformation(params).then((res) => {
        if (res.code == 200) {
          data.active = 5;
          store.commit("setRegisterActive", 5);
        } else {
          ElMessage.error({
            grouping: true,
            message: res.msg,
          });
        }
      });
    } else {
    }
  });
};

//去顾问中心
//第五步注册
const goConCenter = () => {
  imageform.value.id = data.consulutobj.id;
  const loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  postInformation(imageform.value).then((res) => {
    if (res.code == 200) {
      loading.close();
      console.log(res, "照片");
      // ElMessageBox.alert(
      //   'Your information is being reviewed, please click to log in after receiving the SMS',
      //   {
      //     confirmButtonText: 'OK',
      //     // callback: (action) => {
      //     //   ElMessage({
      //     //     type: 'info',
      //     //   })
      //     // }
      //   }
      // )
      data.active = 6;
      timerFn();
    } else {
      loading.close();
      ElMessage.error({
        grouping: true,
        message: res.msg,
      });
    }
  });
};

const goOnlineExpert = () => {
  router.push("/onlineExpert");
};
const goLogin = () => {
  router.push("/login");
};
//去顾问登录
const goToLogin = () => {
  ElMessageBox.confirm("Are you sure you want to log out?", "Warning", {
    confirmButtonText: "OK",
    cancelButtonText: "Cancel",
    type: "warning",
  })
    .then(() => {
      logOut().then(() => {
        removeToken();
        removeType();
        removeConlant();
        localStorage.clear();
        window.localStorage.removeItem("userInfo");
        window.localStorage.removeItem("token");
        store.state.userInfo = null;
        router.push("/onlineExpert");
      });

      // removeToken();
      // localStorage.clear();
      // router.push("/login");
      // setTimeout(() => {
      //   location.reload();
      // }, 0);
    })
    .catch(() => {});
};

let sendEmailLoading = ref(false);
// let seconds = 59
let timer = null;

async function agSendEmail() {
  const loading = ElLoading.service({
    lock: true,
    text: "Loading",
    background: "rgba(0, 0, 0, 0.7)",
  });
  sendEmailLoading.value = true;
  const { code } = await request.post("/auth/faConsultant/sendEmail");
  if (code == 200) {
    ElMessage({
      message: "Successfully resend your email address",
      type: "success",
      duration: 1500,
    });

    sendEmailLoading.value = false;
    loading.close();
  } else {
    sendEmailLoading.value = false;
  }
  // let timer = null
  // sendEmailloadingShowVal.value = true
  // if (timer) {
  //   console.log('clearTimeout')
  //   clearTimeout(timer)
  // }
  // timer = setTimeout(() => {
  //   console.log('timer')
  // }, 1000)

  console.log(code);
}
// 获取顾问信息
async function getLoginConsuInfo() {
  const res = await request.get("/system/faConsultant/getLoginConsuInfo");
  if (res.code == 200) {
    // console.log(data)
    console.log(res.data);
    if (res.data.auditStatus == "2" && data.active != 1) {
      store.commit("setRegisterActive", 1);
      router.push("/profileInfo");
    } else if (res.data.auditStatus == "0") {
      data.active = 2;
      store.commit("setRegisterActive", 2);
    } else if (res.data.auditStatus == "4") {
      data.active = 5;
      store.commit("setRegisterActive", 5);
    } else if (res.data.auditStatus == "5") {
      data.active = 6;
      store.commit("setRegisterActive", 6);
    } else if (res.data.auditStatus == "1") {
      data.active = 4;
      store.commit("setRegisterActive", 4);
    } else if (data.auditStatus == "3") {
      //驳回
      data.active = 4;
      store.commit("setRegisterActive", 4);
    }
    // if (res.data.auditStatus == '1' && res.data.reverseImg) {
    //   data.active = 5
    //   store.commit('setRegisterActive', 5)
    // }
    data.consulutobj = res.data;
    // 判断顾问的当前注册状态 0=注册邮箱未认证,1=注册邮箱已认证提交资料审核,2=资料审核通过,3=资料审核驳回
    // 储存用户信息
    store.commit("setUserInfo", JSON.stringify(res.data));
  } else {
    data.active = 1;
    store.commit("setRegisterActive", 1);
  }
}

async function getLoginConsuInfo11() {
  const res = await request.get("/system/faConsultant/getLoginConsuInfo");
  if (res.code == 200) {
    // console.log(data)
    console.log(res.data);
    if (res.data.auditStatus == "2" && data.active != 1) {
      store.commit("setRegisterActive", 1);
      router.push("/profileInfo");
    } else if (res.data.auditStatus == "0") {
      data.active = 2;
      store.commit("setRegisterActive", 2);
    } else if (res.data.auditStatus == "1") {
      data.active = 4;
      store.commit("setRegisterActive", 4);
    } else if (data.auditStatus == "3") {
      //驳回
      data.active = 4;
      store.commit("setRegisterActive", 4);
    }
    // if (res.data.auditStatus == '1' && res.data.reverseImg) {
    //   data.active = 5
    //   store.commit('setRegisterActive', 5)
    // }
    data.consulutobj = res.data;
    // 判断顾问的当前注册状态 0=注册邮箱未认证,1=注册邮箱已认证提交资料审核,2=资料审核通过,3=资料审核驳回
    // 储存用户信息
    store.commit("setUserInfo", JSON.stringify(res.data));
  } else {
    data.active = 1;
    store.commit("setRegisterActive", 1);
  }
}
</script>
